
body
{
    background-color: #E2E0E0;
    font-family: 'Montserrat', sans-serif, Arial;
    font-size: 20px;
    line-height: 35px;
    font-weight: 400;
    color: #191919;
    letter-spacing: -1px;
}

body a
{
    text-decoration: none;
    color: #191919;
    transition: color .2s linear, background-color .2s linear, border-color .2s linear;
}

body a:hover
{
    color: #FD3137;
}

input, textarea, select
{
    -webkit-appearance: none;
}

.body-wrapper
{
    margin: 24px;
    position: relative;
}

.doc-loader
{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: white;
}

.doc-loader td
{
    text-align: center;
    vertical-align: middle;
}

.doc-loader img
{
    width: 120px;
    height: 120px;
}

.nicescroll-rails-vr:hover
{
    cursor: pointer !important;
}

.header-search
{
    background-color: #E2E0E0;
    padding: 100px 0;
    margin-top: -105px;
}

.search-form
{
    text-align: center;
}

.search-field
{
    width: 100%;
    font-size: 24px;
    padding: 15px;
    border: 0;
    background: transparent;
    border-bottom: 2px solid #a1a1a1;
    color: #a1a1a1;
    font-weight: 400;
    max-width: 80%;
}

.search-field::-webkit-input-placeholder
{
    font-family: 'Montserrat', sans-serif, Arial;
    color: #a1a1a1;
    font-weight: 400;
}
.search-field::-moz-placeholder
{
    font-family: 'Montserrat', sans-serif, Arial;
    color: #a1a1a1;
    font-weight: 400;
    opacity: 1;
}
.search-field:-ms-input-placeholder
{
    font-family: 'Montserrat', sans-serif, Arial;
    color: #a1a1a1;
    font-weight: 400;
}
.search-field:-moz-placeholder
{
    font-family: 'Montserrat', sans-serif, Arial;
    color: #a1a1a1;
    font-weight: 400;
    opacity: 1;
}

.header-holder
{
    padding-top: 32px;
    padding-bottom: 16px;
    margin-left: auto;
    margin-right: 16px;
}

#header-main-menu
{
    display: inline-block !important;
}

.main-menu.sm-clean
{
    background-color: transparent;
    display: inline-block !important;
}

.main-menu li {
    border-top: none !important;
    display: inline-block !important;
    margin: 0 4px !important;
}

.sm-clean a.current, .sm-clean a:hover, .sm-clean a:focus, .sm-clean a:active,
.sm-clean a.highlighted, .sm-clean ul a:hover, .sm-clean ul a:focus,
.sm-clean ul a:active, .sm-clean ul a.highlighted, .sm-clean .current_page_item a
{
    color: #FD3137;
}

.sub-arrow
{
    -webkit-transition: transform 0.1s ease;
    -moz-transition: transform 0.1s ease;
    -ms-transition: transform 0.1s ease;
    -o-transition: transform 0.1s ease;
    transition: transform 0.1s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.main-menu a.has-submenu.highlighted .sub-arrow
{
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.content-1330
{
    max-width: 1330px;
}

.content-1530
{
    max-width: 1200px;
    width: 90%;
}

.content-1370 img, .content-1430 img, .content-1530 img
{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

ul.slides li img
{
    width: 100%;
    height: auto;
}

.toggle-holder
{
    width: 29px;
    height: 30px;
}

#toggle
{
    width: 29px;
    height: 30px;
    display: inline-block;
    position: absolute;
    z-index: 2;
}

#toggle .fa-search
{
    font-size: 1.3em;
    transition: color .2s linear, zoom .2s linear;
}

#toggle.on .fa-search:before
{
    content: "\f010";
}

#toggle .fa-search:hover
{
    cursor: pointer;
    zoom: 1.1;
    color: #fd3137;
}

.caroufredsel_wrapper:before
{
    content: "";
    position: absolute;
    width:100%;
    height:100%;
    left: 0;
    top: 0;
    z-index: 0;
}

.carousel_pagination a
{
    background-color: #D7D7D7;
}

.carousel_pagination a:hover
{
    background-color: #656565;
}

.carousel_pagination a.selected
{
    background-color: #656565;
}

.page-desc
{
    width: 925px;
    float: right;
    font-size: 24px;
    line-height: 40px;
}

/* BLOG */

.blog-top-content.page-desc
{
    margin-bottom: 170px;
    max-width: 720px;
}

.blog-item-holder
{
    opacity: 0;
    padding: 35px 0;
    -webkit-transition: background 0.5s ease;
    -moz-transition: background 0.5s ease;
    -ms-transition: background 0.5s ease;
    -o-transition: background 0.5s ease;
    transition: background 0.5s ease;
}

.blog-item-holder:hover
{
    background-color: #FAF6F6;
}

.blog-item-holder:hover .entry-title a
{
    color: #FD3137;
}

.blog-item-holder.sticky
{
    background-color: #FAF6F6;
}

.blog-holder .cat-links, .blog-holder .entry-date, .blog-holder .author, .blog-holder .entry-title
{
    display: inline-block;
    vertical-align: middle;
}

.blog-holder .post-border
{
    border-bottom: 1px solid #eeeeee;
    opacity: 0;
}

.blog-holder .cat-links
{
    min-width: 200px;
    padding-left: 20px;
}

.blog-holder .cat-links li
{
    margin: 20px 0;
}

.blog-holder .cat-links a
{
    color: #FD3137;
    border: 3px solid #FD3137;
    text-decoration: none;
    padding: 10px;
    font-weight: 700;
}

.blog-holder .entry-date
{
    margin: 0 75px;
    color: #adadad;
    font-size: 24px;
}

.blog-holder .author
{
    width: 200px;
    color: #adadad;
    font-size: 24px;
}

.blog-holder .entry-title
{
    max-width: 700px;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.blog-holder .entry-title a
{
    -webkit-transition: color 0.5s ease;
    -moz-transition: color 0.5s ease;
    -ms-transition: color 0.5s ease;
    -o-transition: color 0.5s ease;
    transition: color 0.5s ease;
    font-size: 34px;
    line-height: 48px;
}

body.blog .more-posts
{
    color: #FD3137;
    border: 3px solid #FD3137;
    padding: 25px 10px;
    display: block;
    cursor: pointer;
    font-weight: 700;
    font-size: 28px;
}

body.blog .more-posts span
{
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    vertical-align: 2px;
}

body.blog .more-posts:hover span
{
    display: inline-block;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

/* END BLOG */


/* SINGLE POST */

.single-post h1.entry-title
{
    width: 870px;
    float: right;
    font-weight: 700;
    font-size: 46px;
    line-height: 55px;
    letter-spacing: -2px;
    margin-bottom: 90px;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.single-post .post-info
{
    text-align: left;
    float: left;
    width: 330px;
    margin-right: 130px;
}

.single-post .post-info .post-author a
{
    text-decoration: none;
    color: #FD3137;
}

.single-post .post-info  .post-num-comments a
{
    color: #FD3137;
    text-decoration: none;
}

.single-post .content-wrap
{
    float: right;
    width: 870px;
    font-family: 'PT Serif', serif;
    font-size: 22px;
    line-height: 40px;
    letter-spacing: 0;
}

.single-post .content-wrap img
{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.post-full-width .fluid-width-video-wrapper
{
    float: right;
}


.single-post .cat-links a
{
    color: #FD3137;
    border: 3px solid #FD3137;
    text-decoration: none;
    padding: 10px;
    font-weight: 700;
}

.single-post .cat-links
{
    min-width: 200px;
}

.single-post .cat-links li
{
    margin: 20px 0;
}

.wp-caption p.wp-caption-text
{
    font-size: 18px;
    line-height: 30px;
}

.single-post .tags-holder a
{
    color: #b5b5b5;
    border: 3px solid #b5b5b5;
    text-decoration: none;
    padding: 10px;
    font-weight: 700;
    margin: 10px;
    display: inline-block;
}

.single-post .tags-holder a:hover
{
    color: #b5b5b5;
}

.single-post .tags-holder
{
    margin: 50px 0;
}

.single-post .wp-link-pages span
{
    color: #191919;
}

.single-post .wp-link-pages
{
    color: #FD3137;
}

.single-post .nav-links
{
    float: right;
    width: 870px;
    margin-top: 50px;
    margin-bottom: 120px;
    border-top: 6px solid #e2e0e0;
    border-bottom: 6px solid #e2e0e0;
    padding: 60px 0;
}

.single-post .nav-previous
{
    display: inline-block;
    max-width: 350px;
}

.single-post .nav-next
{
    display: inline-block;
    float: right;
    max-width: 350px;
}

.single-post .nav-previous a, .single-post .nav-next a
{
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    display: block;
}

.single-post .nav-previous-text, .single-post .nav-next-text
{
    color: #b5b5b5;
    font-weight: 700;
    padding-bottom: 30px;
}

/* END SINGLE POST */


/* ABOUT PAGE */

ul.archive-list li
{
    border-top: 1px solid #e2e0e0;
    padding: 15px 20px;
    font-size: 18px;
}

ul.archive-list li:first-child
{
    border: none;
}

ul.archive-list li span
{
    font-weight: 700;
    color: #fd3137;
}

/* END ABOUT PAGE */


/* PORTFOLIO */

.filters-button-group
{
    text-align: right;
    display: block;
    margin-bottom: 50px;
}

.filters-button-group .button
{
    display: inline-block;
    transition: color .2s linear;
}

.filters-button-group .button.is-checked
{
    color: #FD3137;
}

.filters-button-group .button:hover
{
    color: #FD3137;
    cursor: pointer;
}

.filters-button-group .button:after
{
    content: "\2022";
    display: inline-block;
    margin: 0 20px;
    color: #e2dfd9;
}

.filters-button-group .button:last-child:after
{
    content: '';
    display: none;
}

.grid
{
    width: 100%;
    margin: 50px auto 0;
    overflow: hidden;
    position: relative;
    display: block;
}

@media only screen and (max-width: 580px) {
    .grid {
        margin-top: 20px;
    }
}
.grid-item
{
    float: left;
    font-size: 0;
    line-height: 0;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
}

.home-page-img {
	box-sizing: border-box;
	padding: 3px;
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
	transition: filter 0.3s ease-out;
}

.home-page-img:hover {
	-webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
	filter: grayscale(0);
}

.home-profile-pic {
	width: 16vw;
}
#content .grid-item img
{
    display: block;
    width: 100%;
    height: auto;
    max-height: none;
    max-width: none;
}

.grid-sizer, .grid-item
{
    width: 443px;
}

.grid-item.p_two_third
{
    width: 886px;
}

.grid-item.p_one
{
    width: 1329px;
}

.portfolio-text-holder
{
    position: absolute;
    top: 30px;
    left: 30px;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    font-size: 22px;
	font-weight: bold;
	color: #000000;
    text-align: center;
	text-shadow: white 3px 3px 5px;
    display: none;
}

.portfolio-text-holder p {
  line-height: 32px;
}

.grid-item a:hover
{
    color: #191919;
}

div.pp_default .pp_loaderIcon
{
    display: none !important;
}

/* END PORTFOLIO */


/* PORTFOLIO SINGLE */

.single-portfolio h1.entry-title
{
    font-weight: 700;
    font-size: 46px;
    line-height: 55px;
    letter-spacing: -2px;
    margin-bottom: 90px;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.single-portfolio .entry-title
{
    text-align: center;
}

/* END PORTFOLIO SINGLE */


/* CONTACT */

.contact-form
{
    max-width: 100%;
    margin: 0 auto;
}

.contact-form input[type=text], .contact-form input[type=email], .contact-form textarea
{
    border: 0;
    border-bottom: 2px solid;
    padding-top: 13px;
    padding-bottom: 13px;
    margin: 30px 0;
    height: 28px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif, Arial;
    width: 100%;
    line-height: 25px;
    text-transform: uppercase;
}

.contact-form input[name="your-name"]
{
    margin-top: 0;
    padding-top: 0;
}

.contact-form textarea
{
    height: 150px;
}

.contact-form input[type=submit]
{
    display: inline-block;
    border: 2px solid;
    text-align: center;
    padding: 30px 0px;
    cursor: pointer;
    margin-bottom: 25px;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    font-family: 'Montserrat', sans-serif, Arial;
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
    font-size: 20px;
    transition: color .2s linear, background-color .2s linear, border-color .2s linear;
}
.contact-form input[type=submit]:hover
{
    color: #FD3137;
    border-color: #FD3137;
}

.contact-form input[type=text]::-webkit-input-placeholder, .contact-form input[type=email]::-webkit-input-placeholder, .contact-form textarea::-webkit-input-placeholder
{
    font-family: 'Montserrat', sans-serif, Arial;
    color: #191919;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
}
.contact-form input[type=text]::-moz-placeholder, .contact-form input[type=email]::-moz-placeholder, .contact-form textarea::-moz-placeholder
{
    font-family: 'Montserrat', sans-serif, Arial;
    color: #191919;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    opacity: 1;
}
.contact-form input[type=text]:-ms-input-placeholder, .contact-form input[type=email]:-ms-input-placeholder, .contact-form textarea:-ms-input-placeholder
{
    font-family: 'Montserrat', sans-serif, Arial;
    color: #191919;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
}
.contact-form input[type=text]:-moz-placeholder, .contact-form input[type=email]:-moz-placeholder, .contact-form textarea:-moz-placeholder
{
    font-family: 'Montserrat', sans-serif, Arial;
    color: #191919;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    opacity: 1;
}

/* END CONTACT */


/* FOOTER */

.footer
{
    margin-top: 260px;
    padding-bottom: 170px;
    clear: both;
}

.footer .fa-heart
{
    color: #fd3137;
    -webkit-animation: pulse 1s linear infinite;
    -moz-animation: pulse 1s linear infinite;
    -ms-animation: pulse 1s linear infinite;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% { transform: scale(1.1); }
70% { transform: scale(0.8,0.8); }
80% { transform: scale(0.9,0.9); }
100% { transform: scale(1.1);  }
}

@-moz-keyframes pulse {
    0% { transform: scale(1.1); }
70% { transform: scale(0.8,0.8); }
80% { transform: scale(0.9,0.9); }
100% { transform: scale(1.1);  }
}

@-webkit-keyframes pulse {
    0% { transform: scale(1.1); }
70% { transform: scale(0.8,0.8); }
80% { transform: scale(0.9,0.9); }
100% { transform: scale(1.1);  }
}

@-ms-keyframes pulse {
    0% { transform: scale(1.1); }
70% { transform: scale(0.8,0.8); }
80% { transform: scale(0.9,0.9); }
100% { transform: scale(1.1);  }
}

.footer ul li
{
    display: inline-block;
}

li.left-footer-content, li.right-footer-contnet
{
    width: 40%;
}

li.left-footer-content
{
    font-size: 16px;
}

li.right-footer-contnet
{
    text-align: right;
}

li.right-footer-contnet a
{
    margin-left: 20px;
    font-size: 16px;
    display: inline-block;
}

li.right-footer-contnet a:first-child
{
    margin-left: 0;
}

li.center-footer-content
{
    width: 19%;
    text-align: center;
    vertical-align: middle;
}

li.left-footer-content.mobile
{
    display: none;
}

/* END FOOTER */

/* RESPONSIVE PART */

@media screen and (max-width: 1600px) {

    .content-1330
    {
        max-width: 1200px;
    }

    .header-search
    {
        padding: 50px 0;
    }

    .blog-holder .entry-title
    {
        max-width: 400px;
    }

    .single-post h1.entry-title, .single-post .content-wrap, .single-post .nav-links
    {
        width: 740px;
    }

    .one_half,  .one_third,  .two_third,  .three_fourth, .one_fourth
    {
        margin-right: 8%;
        float: left;
        position: relative;
        margin-bottom: 37px;
    }

    .one_half{
        width: 46%;
    }
    .one_third{
        width: 28%;
    }
    .two_third{
        width: 64%;
    }
    .one_fourth{
        width: 19%;
    }
    .three_fourth{
        width: 73%;
    }

    .grid-sizer, .grid-item
    {
        width: 400px;
    }

    .grid-item.p_two_third
    {
        width: 800px;
    }

    .grid-item.p_one
    {
        width: 1200px;
    }

}


@media screen and (max-width: 1450px) {

    .body-wrapper
    {
        margin: 1%;
    }

    .header-holder
    {
        padding-top: 32px;
        padding-bottom: 16px;
    }

    .blog-top-content.page-desc
    {
        margin-bottom: 120px;
    }

    .content-1330
    {
        width: 1110px;
    }

    .grid-sizer, .grid-item
    {
        width: 370px;
    }

    .grid-item.p_two_third
    {
        width: 740px;
    }

    .grid-item.p_one
    {
        width: 1110px;
    }

    .single-post .post-info
    {
        margin-right: 35px;
    }

    .footer
    {
        margin-top: 170px;
    }

}


@media screen and (max-width: 1340px) {

    .content-1330
    {
        width: 1020px;
    }

    .grid-sizer, .grid-item
    {
        width: 340px;
    }

    .grid-item.p_two_third
    {
        width: 680px;
    }

    .grid-item.p_one
    {
        width: 1020px;
    }

    .single-post .cat-links
    {
        margin-bottom: 30px;
    }

    .single-post .post-author, .single-post .post-date, .single-post .post-num-comments, .single-post .post-read-time
    {
        margin: 0 15px;
    }

    .page-desc
    {
        float: none;
        width: auto;
    }

    .blog-holder article
    {
        text-align: center;
    }

    .blog-holder .cat-links
    {
        display: block;
        padding-left: 0;
    }

    .blog-holder .entry-date
    {
        margin: 0 20px;
    }

    .blog-holder .author
    {
        width: auto;
    }

    .blog-holder .entry-title
    {
        display: block;
        max-width: 600px;
        margin: 0 auto;
    }

    .single-post h1.entry-title
    {
        text-align: center;
    }

    .single-post .post-info
    {
        float: none;
        width: auto;
        margin: 50px 0;
        text-align: center;
    }

    .single-post .cat-links
    {
        min-width: 0;
    }

    .single-post .cat-links li
    {
        margin: 10px 5px;
        display: inline-block;
    }

    .single-post .post-author, .single-post .post-date, .single-post .post-num-comments, .single-post .post-read-time
    {
        display: inline-block;
    }

    blockquote.inline-blockquote
    {
        display: block;
        margin: 50px 0;
        width: auto;
    }

    .single-post h1.entry-title, .single-post .content-wrap, .single-post .nav-links
    {
        width: 100%;
    }

}
@media screen and (max-width: 1220px) {

    .content-1330, .grid-item.p_one
    {
        width: 960px;
    }

    .grid-sizer, .grid-item
    {
        width: 320px;
    }

    .grid-item.p_two_third
    {
        width: 640px;
    }

    div.contact-form
    {
        margin-top: 170px;
    }

}

@media screen and (max-width: 1066px) {

    .content-1330, .grid-item.p_one
    {
        width: 810px;
    }

    .grid-sizer, .grid-item
    {
        width: 270px;
    }

    .grid-item.p_two_third
    {
        width: 540px;
    }

    .footer
    {
        margin-top: 140px;
        padding-bottom: 85px
    }

    .footer ul li
    {
        display: block;
        margin: 0 auto;
        width: 100%;
        text-align: center;
    }

    .footer ul li.center-footer-content
    {
        margin-bottom: 45px;
    }

    .footer ul li.left-footer-content
    {
        display: none;
    }

    .footer ul li.left-footer-content.mobile
    {
        display: block;
    }

}

@media screen and (max-width: 1020px) {

    .one_half, .one_third, .one_fourth, .two_third, .three_fourth, .one_half.margin-0, .one_third.margin-0, .two_third.margin-0, .one_fourth.margin-0, .three_fourth.margin-0
    {
        width: 100%;
        float: none;
        margin-right: 0;
        margin-left: 0;
    }

    .single-portfolio .one_half.margin-0 img, .single-portfolio .one_third.margin-0 img, .single-portfolio .two_third.margin-0 img, .single-portfolio .one_fourth.margin-0 img, .single-portfolio .three_fourth.margin-0 img
    {
        width: 100%;
        height: auto;
    }

    .single-portfolio .portfolio-item-info, .single-portfolio .portfolio-item-info div
    {
        text-align: center;
    }

}

@media screen and (max-width: 925px) {

    .content-1330,  .grid-item.p_one
    {
        width: 690px;
    }

    .single-post h1.entry-title
    {
        margin-bottom: 50px;
        text-align: center;
        float: none;
    }

    .grid-sizer, .grid-item
    {
        width: 230px;
    }

    .grid-item.p_two_third
    {
        width: 460px;
    }

    .portfolio-text-holder
    {
        top: 10px;
        left: 10px;
        bottom: 10px;
        right: 10px;
    }

    .header-holder
    {
        padding-top: 32px;
        padding-bottom: 16px;
    }

    .header-logo
    {
        float: none;
        display: block;
        text-align: center;
        margin-bottom: 60px;
    }

    #toggle
    {
        display: none;
    }

    /*.header-menu*/
    /*{*/
    /*    float: none;*/
    /*}*/

    /*#header-main-menu*/
    /*{*/
    /*    display: block !important;*/
    /*    margin: 0 !important;*/
    /*    opacity: 1 !important;*/
    /*    float: none;*/
    /*}*/

    /*.main-menu a.has-submenu.highlighted .sub-arrow*/
    /*{*/
    /*    -webkit-transform: none;*/
    /*    -moz-transform: none;*/
    /*    -ms-transform: none;*/
    /*    -o-transform: none;*/
    /*    transform: none;*/
    /*}*/

    /*.main-menu*/
    /*{*/
    /*    float: none;*/
    /*    margin: 0 auto;*/
    /*    text-align: center;*/
    /*    max-width: 300px;*/
    /*    width: 100%;*/
    /*    position: relative;*/
    /*    z-index: 9999;*/
    /*}*/

    /*.main-menu ul*/
    /*{*/
    /*    width: 12em; !* fixed width only please - you can use the "subMenusMinWidth"/"subMenusMaxWidth" script options to override this if you like *!*/
    /*}*/

    .sm-clean a, .sm-clean a:hover, .sm-clean a:focus, .sm-clean a:active
    {
        padding-right: 0;
        padding-left: 0;
    }

    .single-post .nav-previous
    {
        margin-bottom: 80px;
    }

    .single-post .nav-previous, .single-post .nav-next
    {
        display: block;
        text-align: center;
        max-width: none;
        float: none;
    }

}

@media screen and (max-width: 805px) {

    .content-1330, .grid-item.p_one
    {
        width: 570px;
    }

    .grid-sizer, .grid-item
    {
        width: 190px;
    }

    .grid-item.p_two_third
    {
        width: 380px;
    }

    blockquote.inline-blockquote
    {
        padding-left: 20px;
        font-size: 20px;
        line-height: 32px;
    }

    .single-post .tags-holder a
    {
        font-size: 16px;
        line-height: 100%;
    }

}

@media screen and (max-width: 650px) {

	.page-desc {
		font-size: 14px;
	}

    .header-holder
    {
        padding-top: 32px;
        padding-bottom: 16px;
    }

    .content-1330
    {
        width: 90%;
    }

    .grid-sizer, .grid-item, .grid-item.p_one, .grid-item.p_two_third
    {
        width: 100%;
    }

    .filters-button-group
    {
        text-align: center;
    }

    .portfolio-text-holder
    {
        top: 30px;
        left: 30px;
        right: 30px;
        bottom: 30px;
    }

    .single-post .content-wrap
    {
        font-size: 18px;
        line-height: 33px;
    }
}
@media only screen and (max-width: 580px) {
    .content-1530
    {
        width: 100% !important;
    }
    .body-wrapper {
        margin-left: 0;
        margin-right: 0;
    }
    /*.image-slider-wrapper {*/
    /*    height: 360px;*/
    /*}*/
    /*.image-slider img {*/
    /*    height: 360px !important;*/
    /*    object-fit: cover;*/
    /*}*/
}
